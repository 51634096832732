import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { clsNames, dConfirm, dError, get, linkClick, post, tSuccess, useAppContext, useUser } from 'unno-comutils'
import { logout } from 'unno-comutils/connect'
import { Icon, Modal, PageScroll } from 'unno-comutils/ui'
import { FormContainer, Input, Photo } from 'unno-comutils/form'

import { getMenu } from './service/menu'

export default function Layout (props: any) {
    return <div className="layout-area">
        <SideMenu/>
        <div className="layout-content">
            {props.children}
        </div>
    </div>
}

function SideMenu () {
    const ctx = useAppContext()
    const user = useUser()
    const history = useHistory()

    const { pathname } = useLocation()

    const [miniSideMenu, setMiniSideMenu] = useState(localStorage.getItem('SOMMAI_SIDEMENU_MINI') === '1')
    const [profile, setProfile] = useState(false)

    const userLogout = () => {
        dConfirm('Logout ?').then(() => {
            logout()
                .then(() => {
                    tSuccess('ออกจากระบบแล้ว')
                    ctx.setUser(null)
                })
        })
    }

    // ----- MEMO

    const P = useMemo(() => pathname + '/', [pathname])
    const menus = useMemo(() => getMenu(P), [pathname])

    useEffect(() => {
        localStorage.setItem('SOMMAI_SIDEMENU_MINI', miniSideMenu ? '1' : '')
    }, [miniSideMenu])

    if (!menus) return null

    return <>
        <div className={clsNames('layout-sidemenu', miniSideMenu && '-mini', P.startsWith('/console') && '-console')}>
            {!!user && <div className="layout-user">
                <Icon href={'/home'} button name={'pills'} className={'pink icon-home'} onClick={e => linkClick(e, history)}/>
                <div className={'_user'}>
                    <div className="_name">{user.name}</div>
                    <div className="_version">v{process.env.REACT_APP_VERSION}</div>
                </div>
                <Icon button name={'power-off grey'} onClick={userLogout}/>
            </div>}
            <div className="layout-menu">
                <PageScroll>
                    {menus.filter((m: any) => !m.role || user.allow(m.role))
                        .map((item: any, rn: number) => {
                            if (item === true) return <div key={rn} className="_menu-div"/>
                            return <a key={item.page} href={item.url} onClick={e => linkClick(e, history)}
                                      className={clsNames('_menu', (P.startsWith(item.url + '/') || (item.matchUrl && P.startsWith(item.matchUrl))) && '-active')}>
                                <Icon name={item.icon}/>
                                <span className="_text">{item.name}</span>
                            </a>
                        })}
                </PageScroll>

                {user.admin && (P.startsWith('/console')
                    ? <a href={'/home'} onClick={e => linkClick(e, history)} className={'_menu'}><Icon name={'arrow-left'}/><span className="_text">กลับ</span></a>
                    : <a href={'/console'} onClick={e => linkClick(e, history)} className={'_menu'}><Icon name={'cog'}/><span className="_text">ตั้งค่าระบบ</span></a>)}
                <div className="btn-coll"><Icon button name={'chevron-double-' + (miniSideMenu ? 'right' : 'left')} onClick={() => setMiniSideMenu(prev => !prev)}/></div>
            </div>
        </div>

        <UserForm user={user} open={profile} onSave={() => setProfile(false)} onClose={() => setProfile(false)}/>
    </>
}

function UserForm (props: any) {
    const [employee, setEmployee] = useState<any>(null)

    const [passwordNew, setPasswordNew] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [password, setPassword] = useState('')

    const onLoad = () => {
        setPasswordNew('')
        setPasswordConfirm('')
        setPassword('')

        get('app/profile')
            .then(({ ok, employee }) => {
                if (ok) {
                    setEmployee(employee)
                }
                else {
                    setEmployee(null)
                }
            })
    }

    const onChange = (update: any) => setEmployee({ ...employee, ...update })

    const onSave = (c: any) => {
        post('app/profile_save', { passwordNew, passwordConfirm, password, employee }).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลสำเร็จ')
                props.onSave()
            }
            else {
                dError(d)
            }
        }).finally(c)
    }

    return <Modal title={'ข้อมูลส่วนตัว'} lg noCloseBackdrop open={props.open} onClose={props.onClose} onOpenEnd={onLoad} footerSave={onSave}>
        {employee && <>
            <FormContainer label={'ข้อมูลหลัก'}>
                <div className="flex">
                    <Photo label={'ภาพพนักงาน'} classBox={'mr-3'} tag="person" value={employee.photos || ''} onChange={v => onChange({ photos: v })}/>
                    <Photo label={'บัตรประชาชน'} classBox={'mr-3'} tag="idcard" value={employee.photos || ''} onChange={v => onChange({ photos: v })}/>
                </div>
                <div className="row">
                    <Input label={'ชื่อเล่น'} classBox="col w-2/12" center defValue={employee.name} key={'iname_' + employee.name} onBlur={v => onChange({ name: v })}/>
                    <Input label={'ชื่อ-สกุล'} classBox="col flex-1" defValue={employee.name_s} key={'iname_s_' + employee.name_s} onBlur={v => onChange({ name_s: v })}/>
                </div>
            </FormContainer>
        </>}
        <FormContainer label={'แก้ไขรหัสผ่าน'} classControl="flex">
            <Input label="รหัสผ่านใหม่" className="w-52" note={'หากไม่ต้องการแก้ไข ไม่ต้องกรอก'} value={passwordNew} onChange={v => setPasswordNew(v)}/>
            <Input label="ยืนยันรหัสผ่านใหม่" className="w-52 ml-4" value={passwordConfirm} onChange={v => setPasswordConfirm(v)}/>
        </FormContainer>
        <FormContainer label={'ยืนยันข้อมูล'}>
            <Input label="รหัสผ่านเดิม" type="password" className="w-52" value={password} onChange={v => setPassword(v)}/>
        </FormContainer>
    </Modal>
}