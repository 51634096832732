export const MainMenus = [
    { page: 'order', name: 'สินค้าต้องสั่งซื้อ', icon: 'bags-shopping', url: '/order', role: 'order' },
    { page: 'withdraw', name: 'เบิกสาขา', icon: 'share', url: '/withdraw', role: 'withdraw' },
    { page: 'order_history', name: 'ประวัติการสั่งซื้อ', icon: 'file-signature', url: '/order_history' },
    //{ page: 'chat', name: 'Chat', icon: 'comments', url: '/chat' },
    true,
    // { page: 'gov7', name: 'รายงาน ขย. 7', icon: 'dice-one', url: '/report/gov7', role: 'report.gov7' },
    { page: 'gov9', name: 'รายงาน ขย. 9', icon: 'dice-two', url: '/report/gov9', role: 'report.gov9' },
    { page: 'cheer', name: 'สรุปสินค้าเชียร์', icon: 'dice-three', url: '/report/cheer', role: 'report.cheer' },
    { page: 'cheer_sale', name: 'สรุปผลงานพนักงาน', icon: 'dice-four', url: '/report/cheer_sale', role: 'report.cheer_sale' },
    { page: 'search', name: 'รายงานแจกแจง', icon: 'dice-five', url: '/report/search', role: 'report.search' },

    true,
    { page: 'product', name: 'สินค้า', icon: 'capsules', url: '/product', role: 'product' },
    { page: 'product_stock', name: 'สินค้าค้างตัดสต๊อค', icon: 'send-back', url: '/product_stock', role: 'product_stock' },
    true,
    { page: 'chat', name: 'ห้องแชท', icon: 'comments', url: '/chat', role: 'chat' },
    true,
    { page: 'customer', name: 'ลูกค้า', icon: 'child', url: '/customer', role: 'customer' },
    { page: 'partner', name: 'บริษัทยา', icon: 'clipboard-user', url: '/partner', role: 'partner' },
    { page: 'employee', name: 'พนักงาน', icon: 'users', url: '/employee', role: 'employee' },
    true,
    { page: 'import', name: 'นำเข้าข้อมูล', icon: 'cloud-upload', url: '/import', role: 'import' },
    { page: 'stock_calendar', name: 'ปฏิทินสต๊อค', icon: 'calendar-alt', url: '/stock_calendar', role: 'stock_calendar' },
    //{ page: 'import_change', name: 'เปลี่ยนแปลงข้อมูล', icon: 'cloud-sleet', url: '/import_change' },
    true,
    { page: 'document', name: 'คลังข้อมูล', icon: 'books-medical', url: '/document', role: 'document' },
    { page: 'meta', name: 'ข้อมูลพื้นฐาน', icon: 'server', url: '/meta', role: 'meta' }
]

export const ConsoleMenus = [
    { page: 'admin_user', name: 'ผู้ใช้งาน', icon: 'users', url: '/console/user', role: '__admin' },
    { page: 'admin_user_group', name: 'กลุ่มผู้ใช้งาน', icon: 'book-user', url: '/console/user_group', role: '__admin' },
    true,
    { page: 'lab_db', name: 'ตรวจสอบฐาน', icon: 'database', url: '/console/lab_db', role: '__admin' },
    true,
    { page: 'card_reader', name: 'อ่านบัตร สปสช.', icon: 'id-card', url: '/console/card_reader', role: '__admin' },
    { page: 'card_reader2', name: 'อ่านบัตร ปชช.', icon: 'id-card', url: '/console/card_reader2', role: '__admin' },
]

export function getMenu (cPath: string) {
    if (cPath.startsWith('/console/')) return ConsoleMenus
    return MainMenus
}

export const REPORTs = [
    { report: 'gov9', name: 'รายงาน ขย. 9', role: 'report.gov9' },
    { report: 'cheer', name: 'สรุปสินค้าเชียร์', role: 'report.cheer' },
    { report: 'cheer_sale', name: 'สรุปผลงานพนักงาน', role: 'report.cheer_sale' },
    { report: 'search', name: 'รายงานแจกแจง', role: 'report.search' },
]

export const ProductMenus = [
    { page: 1, name: 'ใช้งาน', icon: 'circle green fas' },
    { page: 0, name: 'ยกเลิก', icon: 'circle grey' }
]

export const OrderMenus = [
    { page: 0, name: 'ทั้งหมด', icon: 'border-all', },
    { page: 1, name: 'ยังไม่ได้สั่ง', icon: 'bags-shopping' },
    { page: 2, name: 'สั่งแล้ว', icon: 'box-full' },
    { page: 3, name: 'ไม่ต้องสั่ง', icon: 'comment-alt-times' },
]