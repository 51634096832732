import React from 'react'
import L from '@loadable/component'

import { Redirect, Route, Switch } from 'react-router-dom'

import { RouteNonuser, RoutePrivate } from 'unno-comutils/Route'
import Wait from 'unno-comutils/Wait'
import CardReader from '../pages/console/cardReader'

const fallback = <Wait icon={'fan'}/>

const Login = L(() => import(/* webpackChunkName: "login" */'../login'), { fallback })
const Home = L(() => import(/* webpackChunkName: "home" */'../pages/home'), { fallback })
const Chat = L(() => import(/* webpackChunkName: "chat" */'../pages/chat'), { fallback })

const Meta = L(() => import(/* webpackChunkName: "meta" */'../pages/meta'), { fallback })

const Customer = L(() => import(/* webpackChunkName: "customer" */'../pages/customer'), { fallback })

const Partner = L(() => import(/* webpackChunkName: "partner" */'../pages/partner'), { fallback })
const Employee = L(() => import(/* webpackChunkName: "employee" */'../pages/employee'), { fallback })

const Order = L(() => import(/* webpackChunkName: "order" */'../pages/order'), { fallback })
const Order2 = L(() => import(/* webpackChunkName: "order" */'../pages/order2'), { fallback })
const OrderHistory = L(() => import(/* webpackChunkName: "order_history" */'../pages/orderHistory'), { fallback })
const OrderPurchase = L(() => import(/* webpackChunkName: "order_purchase" */'../pages/orderPurchase'), { fallback })
const Withdraw = L(() => import(/* webpackChunkName: "withdraw" */'../pages/withdraw'), { fallback })
const Withdraw2 = L(() => import(/* webpackChunkName: "withdraw2" */'../pages/withdraw2'), { fallback })

const Product = L(() => import(/* webpackChunkName: "product" */'../pages/product'), { fallback })
const ProductStock = L(() => import(/* webpackChunkName: "product_stock" */'../pages/productStock'), { fallback })

const Import = L(() => import(/* webpackChunkName: "import" */'../pages/import'), { fallback })
const StockCalendar = L(() => import(/* webpackChunkName: "importCalendar" */'../pages/stockCalendar'), { fallback })

const Document = L(() => import(/* webpackChunkName: "document" */'../pages/document'), { fallback })

const ConsoleUser = L(() => import(/* webpackChunkName: "console.user" */'../pages/console/user'), { fallback })
const ConsoleUserGroup = L(() => import(/* webpackChunkName: "console.user-group" */'../pages/console/userGroup'), { fallback })
const ConsoleLabDb = L(() => import(/* webpackChunkName: "console.lab-db" */'../pages/console/labDb'), { fallback })
const ConsoleCardReader = L(() => import(/* webpackChunkName: "console.test" */'../pages/console/cardReader'), { fallback })
const ConsoleCardReader2 = L(() => import(/* webpackChunkName: "console.test" */'../pages/console/cardReader2'), { fallback })

const ReportGov7 = L(() => import(/* webpackChunkName: "document" */'../report/gov7'), { fallback })
const ReportGov9 = L(() => import(/* webpackChunkName: "document" */'../report/gov9'), { fallback })
const ReportCheer = L(() => import(/* webpackChunkName: "document" */'../report/cheer'), { fallback })
const ReportCheerSale = L(() => import(/* webpackChunkName: "document" */'../report/cheer_sale'), { fallback })
const ReportCheerSearch = L(() => import(/* webpackChunkName: "document" */'../report/search'), { fallback })

export default function Routing () {
    return <Switch>
        <Route exact path="/" children={<Redirect to="/home"/>}/>

        <RouteNonuser exact path="/login" children={<Login/>}/>
        <RoutePrivate exact path="/home" children={<Home/>}/>
        <RoutePrivate needRole={'chat'} path={['/chat/:id', '/chat']} children={<Chat/>}/>

        <RoutePrivate needRole={'meta'} exact path="/meta/:page" children={<Meta/>}/>
        <RoutePrivate needRole={'meta'} exact path="/meta" children={<Meta/>}/>

        <RoutePrivate needRole={'customer'} exact path="/customer" children={<Customer/>}/>

        <RoutePrivate needRole={'partner'} exact path="/partner" children={<Partner/>}/>
        <RoutePrivate needRole={'employee'} exact path="/employee" children={<Employee/>}/>

        <RoutePrivate needRole={'product'} exact path="/product" children={<Product/>}/>

        <RoutePrivate needRole={'order'} exact path="/order" children={<Order/>}/>
        <RoutePrivate needRole={'order'} exact path="/order2" children={<Order2/>}/>
        <RoutePrivate needRole={'order'} exact path="/order_history" children={<OrderHistory/>}/>
        <RoutePrivate needRole={'order.purchase'} exact path="/order/purchase" children={<OrderPurchase/>}/>
        <RoutePrivate needRole={'withdraw'} exact path="/withdraw" children={<Withdraw/>}/>
        <RoutePrivate needRole={'withdraw'} exact path="/withdraw2" children={<Withdraw2/>}/>

        {/*<RoutePrivate needRole={'report'} exact path="/report" children={<Report/>}/>
        <RoutePrivate needRole={'report'} exact path="/report/:page" children={<Report/>}/>*/}

        <RoutePrivate needRole={'product_stock'} exact path="/product_stock" children={<ProductStock/>}/>

        <RoutePrivate needRole={'import'} exact path="/import" children={<Import/>}/>
        <RoutePrivate needRole={'stock_calendar'} exact path="/stock_calendar" children={<StockCalendar/>}/>

        <RoutePrivate needRole={'document'} exact path="/document" children={<Document/>}/>

        {/*<RoutePrivate needRole={'report.gov7'} exact path="/report/gov7" children={<Gov7/>}/>*/}
        <RoutePrivate needRole={'report.gov9'} exact path="/report/gov9" children={<ReportGov9/>}/>
        <RoutePrivate needRole={'report.cheer'} exact path="/report/cheer" children={<ReportCheer/>}/>
        <RoutePrivate needRole={'report.cheer_sale'} exact path="/report/cheer_sale" children={<ReportCheerSale/>}/>
        <RoutePrivate needRole={'report.search'} exact path="/report/search" children={<ReportCheerSearch/>}/>

        <RoutePrivate needRole={'__admin'} exact path="/console/user" children={<ConsoleUser/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/console/user_group" children={<ConsoleUserGroup/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/console/lab_db" children={<ConsoleLabDb/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/console/card_reader" children={<ConsoleCardReader/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/console/card_reader2" children={<ConsoleCardReader2/>}/>
    </Switch>
}
/*<RouterNonuser path="/*"><Redirect to="/login"/></RouterNonuser>*/
