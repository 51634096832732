import React, { useEffect, useMemo, useRef, useState } from 'react'

import { BrowserRouter } from 'react-router-dom'

import { AppContext, clsNames, useAppStateContext } from 'unno-comutils'
import { User } from 'unno-comutils/utils'
import { checkLogin, logout } from 'unno-comutils/connect'
import { ToastContainer } from 'unno-comutils/Dialog'

import 'unno-comutils/style/core.css'
import './style/app.scss'
import './style/tailwind.css'

import Wait from 'unno-comutils/Wait'
import Routing from './service/router'
import Layout from './layout'

export default function App () {
    const [wait, setWait] = useState(0)

    const appState = useAppStateContext()
    const userRef = useRef(appState?.user)

    useEffect(() => {
        setTimeout(() => {
            checkLogin().then((d: any) => {
                if (d.ok) {
                    userRef.current = new User(d.user)
                    appState.setUser(userRef.current)
                    setWait(2)
                }
                else {
                    setWait(2)
                    appState.setUser(null)
                    logout()
                }
            })
            //.finally(() => setWait(false))
        }, 200)

        document.body.className = clsNames('app-body')
    }, [])

    const betaSize = useMemo(() => window?.location.hostname.includes('localhost') || window?.location.hostname.includes('beta'), [window?.location])

    if (wait < 2) return <Wait color={wait ? 'blue' : 'pink'}/>

    return <AppContext.Provider value={appState}>
        <div className={clsNames('layout', betaSize && '-beta')}>
            <BrowserRouter>
                {!!appState.user ? <Layout children={<Routing/>}/> : <Routing/>}
            </BrowserRouter>
            <ToastContainer/>
        </div>
    </AppContext.Provider>
}
